@font-face {
  font-family: 'Gotham Rounded';
  src: url('../fonts/gotham-rounded/book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('../fonts/gotham-rounded/bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('../fonts/gotham-rounded/medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('../fonts/gotham-rounded/light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Anti Alias */

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

/* Body defaults */

html,
body {
  font-family: 'Gotham Rounded', sans-serif;
  font-size: 16px;
  color: #000000;
}

a {
  text-decoration: none;
  color: inherit;
}

/* CONTAINER */

.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: calc(100% - 25px);
  }
  .buddy-savings--shapeItems {
    position: absolute;
    right: 25px !important;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: calc(100% - 25px);
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

/* Text font sizing */

h1 {
  font-size: 2.2rem;
  line-height: 1.2;
}

h2 {
  font-size: 2rem;
  line-height: 1.4;
}

h3 {
  font-size: 1.8rem;
  line-height: 1.5;
}

h4 {
  font-size: 1.42rem;
  line-height: 1.5;
}

h5 {
  font-size: 1.32rem;
  line-height: 1.5;
}

h6 {
  font-size: 1.2rem;
  line-height: 1.5;
}

p {
  font-size: 1rem;
  line-height: 1.5;
}

.text-lg {
  font-size: 2.55rem !important;
  line-height: 1.38;
}

.text-md {
  font-size: 2.15rem;
  line-height: 1.4;
}

.text-sm {
  font-size: 0.8rem;
}

.text-subheading {
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 6px;
}

.text-normal {
  font-weight: 400 !important;
}

.color-primary {
  color: #40196d;
}

.color-black {
  color: #000000;
}

.color-secondary {
  color: #0898a0;
}

.color-white {
  color: #ffffff;
}

.text-semi--bold {
  font-weight: 500;
}

.text-bold {
  font-weight: 700;
}

.text-xbold {
  font-weight: 800;
}

.text-xlbold {
  font-weight: 900;
}

.text-light {
  font-weight: 300;
}

.text-normal {
  font-weight: 400;
}

/* BUTTONS */

.buddy-cta {
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  padding: 11px 35px;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
  background: #99bf18;
  color: #ffffff;
  height: 48px;
  transition: transform 0.6s;
}

.buddy-cta-lg {
  max-width: 198px;
  width: 100%;
}

/**** BACKGROUND *****/

.standard--bg {
  background-color: #e5e5e5;
}

header {
  height: 90px;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: background-color 0.4s ease;
}

.is-sticky {
  background-color: #fff;
  box-shadow: 0px 1px 23px rgba(0, 0, 0, 0.06);
}

.buddy-header--left,
.buddy-menu {
  display: flex;
  align-items: center;
}

.buddy-nav--brand {
  margin-right: 15%;
}

.buddy-menu li:not(:last-child) {
  margin-right: 50px;
}

.buddy-menu li {
  font-weight: 500;
}

.buddy-entry--fold {
  min-height: 720px;
  display: flex;
  align-items: center;
  padding-top: 90px;
  box-shadow: 0px 8px 23px rgba(0, 0, 0, 0.06);
  position: relative;
  overflow: hidden;
}

.fold-illustration img {
  position: absolute;
  right: -600px;
  bottom: -135px;
  width: auto;
  height: 880px;
}

.buddy-entry--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buddy-header--left,
.buddy-nav--actions {
  width: 100%;
}

.buddy-nav--actions ul {
  display: flex;
  justify-content: flex-end;
}

.buddy-nav--actions {
  width: 100%;
  max-width: 400px;
}

.menu-is--button {
  background-color: #99bf18;
  padding: 14px 37px;
  border-radius: 4px;
  color: #fff;
  font-weight: 500 !important;
}

.buddy-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
}

.buddy-section--100 {
  width: 100%;
}

.buddy-section--50 {
  width: 50%;
}

.buddy-section--33 {
  width: 33.33%;
}

.buddy-section--25 {
  width: 25%;
}

.buddy-section--20 {
  width: 20%;
}

.text-heading {
  font-size: 2.6rem;
}

.entry-text--wrap {
  max-width: 480px;
}

.entry-text--wrap .text-heading {
  margin-bottom: 22px;
}

.buddy-cta--lg {
  max-width: 244px;
  margin-top: 32px;
}

.buddy-standard--section {
  padding-top: 5%;
  padding-bottom: 2%;
  overflow: hidden;
  position: relative;
}

.heading-spacer {
  margin-bottom: 22px;
}

.buddy-standard--wrap {
  max-width: 480px;
}

.buddy-standard--wrap .text-subheading {
  font-size: 1.125rem;
}

.buddy-steps--cta {
  background: #ffffff;
  box-shadow: 0px 4px 51px rgba(0, 0, 0, 0.08);
  padding: 10px 22px;
  max-width: 629px;
}

.buddy-step {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 0 22px;
}

.buddy-step:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.buddy-step--text {
  padding: 0 20px 0 40px;
}

.padding-top--small {
  padding-top: 2%;
}

.buddy-screens--wrap {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 9;
}

.buddy-screens--wrap img:not(:last-child) {
  margin-right: 60px;
}

.buddy-center--section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.buddy-center--section .buddy-standard--wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: 867px;
  text-align: center;
  margin-top: 5%;
}

.buddy-cta--section {
  padding: 64px 10%;
  background-color: #99bf18;
  box-shadow: 0px 2px 38px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  background-image: url('../img//savingsShape.svg');
  background-size: cover;
  background-position: center;
}

.buddy-cta--section .buddy-standard--wrap {
  margin: 0;
  max-width: 100%;
}

.buddy-cta--section * {
  color: #ffffff;
}

.cta-item {
  width: calc(50% - 20px);
  max-width: 430px;
}

.buddy-cta--items {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  justify-content: space-between;
}

.cta-item:nth-child(odd) {
  margin-right: 10px;
}

.cta-item:nth-child(even) {
  margin-left: 10px;
}

.cta-item {
  width: calc(50% - 20px);
}

.buddy-cta--items {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  justify-content: space-between;
}

.cta-item:nth-child(odd) {
  margin-right: 10px;
}

.cta-item:nth-child(even) {
  margin-left: 10px;
}

.cta-item:not(:nth-last-child(-n + 2)) {
  margin-bottom: 50px;
}

.buddy-cta--section .heading-spacer {
  margin-bottom: 45px;
}

.buddy-saving--illustration .mock {
  position: absolute;
  top: -80px;
  right: 100px;
}

.buddy-leaf--left svg,
.buddy-leaf--right svg {
  position: absolute;
  bottom: -59px;
}

.buddy-leaf--left svg {
  right: 360px;
  bottom: -155px;
  z-index: 10;
}

.buddy-leaf--right svg {
  right: -20px;
  z-index: -1;
}

.buddy-savings--shapeItems {
  position: absolute;
  right: 10% !important;
  display: flex;
  height: 100%;
  top: 0;
  max-width: 373px;
}

.growth-mock--section {
  padding-top: 150px;
}

.buddy-standard--wrap .text-heading {
  font-size: 2.2rem;
}

.buddy-footer--columns {
  display: flex;
  justify-content: space-between;
}

.footer-column {
  width: 17%;
}

.column-is--two {
  width: 49%;
}

.buddy-footer--columns {
  margin-bottom: 55px;
}

.buddy-footer--columns {
  margin-bottom: 90px;
}

.buddy-footer--menu li:not(:last-child) {
  margin-bottom: 25px;
}

.buddy-footer--menu li {
  font-weight: 500;
}

.footer-column .buddy-cta--lg {
  margin-top: 0;
  width: 100%;
}

.buddy-copyright--section p {
  color: rgba(51, 51, 51, 0.3);
}

.buddy-copyright--section p:not(:last-child) {
  margin-bottom: 20px;
}

.download-badge {
  display: inline-flex;
  cursor: pointer;
  transition: transform 0.6s ease;
}

.download-badge:not(:last-child) {
  margin-right: 5px;
}

.download-badges {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.column-is--two {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.growth-mock--section .buddy-standard--wrap {
  position: relative;
  top: -80px;
}

.leaf-left--below---fold {
  position: absolute;
  top: -14%;
  left: 0;
}

.leaf-right--below---fold,
.coin-left--below---fold {
  position: absolute;
  right: 0;
  top: calc(50% + 80px);
  transform: translateY(-50%);
}

.animate-coin {
  animation: float 6s ease-in-out infinite;
  transform: translatey(0px);
}

.coin-left--below---fold {
  right: auto;
  left: 0;
  top: calc(50% + 60px);
}

.overflow-visible {
  overflow: initial;
}



/* COIN ANIMATION */

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.mobile-leaf-left {
  position: absolute;
  top: 10%;
  left: 0;
}

.mobile-coin-left {
  position: absolute;
  top: calc(10% + 20px);
  animation-delay: 1000ms;
}

.mobile-coin-left-two {
  position: absolute;
  top: calc(20% + 150px);
  left: 7%;
}

.mobile-leaf-right {
  position: absolute;
  right: 0;
  top: 30%;
}

.mobile-leaf-right-two {
  position: absolute;
  right: 0;
  top: 15%;
}

.mobile-coin-right {
  position: absolute;
  right: 0;
  top: 8%;
}

.web-leaf--left,
.web-leaf--left-two {
  position: absolute;
  top: 20%;
}

.web-leaf--left-two {
  top: calc(20% + 70px);
}

.web-coin-left,
.web-coin-left-two {
  position: absolute;
  top: 15%;
  left: 2%;
}

.web-coin-left-two {
  top: calc(20% + 180px);
  left: 5%;
  animation-delay: 1000ms !important;
}

.web-coin-right,
.web-coin-right-two,
.web-coin-right-three,
.web-coin-right-four {
  position: absolute;
  top: 20%;
  right: 0;
}

.web-coin-right-two {
  top: 30%;
  right: 13%;
  animation-delay: 1000ms !important;
}

.web-coin-right-three {
  top: 35%;
  right: 4%;
  animation-delay: 2000ms !important;
}

.web-coin-right-four {
  top: 50%;
  right: 8%;
  animation-delay: 3000ms !important;
}

.cta-item--heading {
  font-weight: 500;
  margin-bottom: 5px;
}

.cta-item--heading img {
  margin-right: 15px;
  height: 17px;
  width: auto;
}

.cta-item--heading span {
  width: 41px;
  display: inline-flex;
  justify-content: center;
}

/*** MOBILE MENU ***/

.hamburger {
  position: relative;
  width: 30px;
  height: 60px;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.hamburger .icon {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  height: 2.5px;
  width: 28px;
  top: 27px;
  background-color: #000000;
}

.hamburger .icon:before {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 22px;
  height: 2px;
  background-color: #000000;
  content: '';
  top: -8px;
}

.hamburger .icon:after {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 22px;
  height: 2px;
  background-color: #000000;
  content: '';
  top: 8px;
}

.hamburger.open .icon {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.hamburger.open .icon:before {
  -webkit-transform: rotateZ(-40deg) scaleX(0.7) translate(-7px, -1px);
  transform: rotateZ(-40deg) scaleX(0.7) translate(-7px, -1px);
}

.hamburger.open .icon:after {
  -webkit-transform: rotateZ(40deg) scaleX(0.7) translate(-7px, 1px);
  transform: rotateZ(40deg) scaleX(0.7) translate(-7px, 1px);
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger.open .icon:before,
.hamburger.open .icon:after {
  width: 23px;
}

.buddy-mobile--menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vh;
  height: 100vh;
  z-index: 99;
  background-color: #99bf18;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 1700ms cubic-bezier(0.455, 0.03, 0, 1) !important;
  -moz-transition: all 1700ms cubic-bezier(0.455, 0.03, 0, 1) !important;
  -o-transition: all 1700ms cubic-bezier(0.455, 0.03, 0, 1) !important;
  transition: all 1700ms cubic-bezier(0.455, 0.03, 0, 1) !important;
  -webkit-transition-delay: 500ms;
  -moz-transition-delay: 500ms;
  -o-transition-delay: 500ms;
  transition-delay: 500ms;
}

.buddy-mobile--menu.show-menu {
  visibility: visible !important;
  -webkit-transition-delay: 100ms !important;
  -moz-transition-delay: 1000ms !important;
  -o-transition-delay: 1000ms !important;
  transition-delay: 1000ms !important;
  opacity: 1;
  -webkit-transition: all 500ms cubic-bezier(0.455, 0.03, 0, 1) !important;
  -moz-transition: all 500ms cubic-bezier(0.455, 0.03, 0, 1) !important;
  -o-transition: all 500ms cubic-bezier(0.455, 0.03, 0, 1) !important;
  transition: all 500ms cubic-bezier(0.455, 0.03, 0, 1) !important;
}

.mobile-active .buddy-nav--brand svg path {
  fill: #fff;
}

.mobile-active .hamburger .icon,
.mobile-active .hamburger .icon:before,
.mobile-active .hamburger .icon:after {
  background-color: #ffffff;
}

.mobile-active header {
  background: none;
  box-shadow: none;
}

.mobile-active {
  overflow: hidden;
}

.buddy-mobile--items li a {
  color: #fff;
  font-size: 1.3em;
  padding: 17px 0;
  display: block;
  font-weight: 500;
}

.buddy-mobile--items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  padding: 150px 20px 100px;
}
