
.de li {
  margin-left: 25px;
  padding: 3px;
  
}

.legal_hero_wrap {
  width: 100%;
  height: auto;
  background: #dddfe6;
  display: flex;
  justify-content: space-between;
  padding: 119px 165px;
  align-items: center;
  flex-wrap: wrap-reverse;
}

.legal_box_wrap {
  margin-top: 48px;
  padding-left: 156px;
  padding-right: 156px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 360px;
}

.legal_header {
  font-weight: bold;
  font-style: normal;
  font-size: 36px;
  line-height: 43.2px;
}

.legal_text {
  width: 509px;
  font-size: 15px;
  margin-top: 17px;
  line-height: 21px;
}
.file_img {
  height: 200px;
  width: 164px;
  margin-top: 81px;
}
.circ {
  height: 31px;
  width: 31px;
  background: rgba(111, 138, 21, 0.3);
  border-radius: 50%;
  margin-right: 8px;
}

.info_box {
  position: relative;
  width: 513px;
  border: 1px solid #7fa49d;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}
.circ_header {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #000000;
}

.info_top {
  display: flex;
  align-items: center;
}

.info_body {
  margin-top: 31px;
  margin-bottom: 21px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #000000;
}

.read_more {
  text-align: right;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #99bf18;
}

@media screen and (max-width: 765px) {
  .info_box {
    width: 100%;
  }
  .legal_box_wrap {
    padding-right: 10%;
    padding-left: 10%;
  }
  .legal_text {
    width: 100%;
  }
  .legal_hero_wrap {
    padding: 10%;
  }
  .file_img {
    width: auto;
    height: 150px;
    margin: 70px auto;
  }
  .circ {
    display: none;
  }
}







// input[name='panel'] {
//   display: none;
// }

// label {
//   display: block;
//   padding: 0.8em;
//   width: 100%;
//   height: 2.5em;
//   border-bottom: 0.1em solid hsl(200, 96%, 80%);
//   background: hsl(200, 96%, 50%);
//   color: hsl(0, 100%, 100%);
//   text-transform: uppercase;
//   font-size: 1.8em;
//   line-height: 1em;
//   cursor: pointer;
// }

// label:hover {
//   background: hsl(200, 96%, 40%);
// }

// .collapsible {
//   display: block;
//   padding: 0 1em;
//   width: 100%;
//   background: hsl(0, 1%, 90%);
// }

// input[name='panel'] + .collapsible {
//   overflow: hidden;
//   height: 0;

//   -webkit-transition: height 1s ease;
//   -moz-transition: height 1s ease;
//   -ms-transition: height 1s ease;
//   -o-transition: height 1s ease;
//   transition: height 1s ease;
// }

// input[name='panel']:checked + .collapsible {
//   overflow: auto;
//   height: 200px; /* needs a numeric value here to keep transition */
// }


/* Variables */

$accordion: 100%; // Accordion width
$panelheight: 200px; // Applied to "max-height" - Must be larger than the panel content
$items: 22; // Specify the number of panels present in HTML

$panel_bg: darken(white, 5%); // bg color of the accordion panel
$label_bg: lighten(#ccc, 10%); // bg color of the accordion labels

// Don't edit below
$label_bg_active: darken($label_bg,7.5%);
$num_bg: darken($label_bg_active,15%);
$border_col: darken($label_bg,40%);
$label_color: black;
// Don't edit above

$gutter: 25px; // General padding around items
$ts: all 0.2s ease; // General transition effect

/* Styles */

* {
  box-sizing: border-box;
}

%reset {
  margin: 0;
  padding: 0;
}





.block {
  max-width: $accordion;
  width: 100%;
  height: 100%;
  float: left;
  padding: $gutter;
  z-index: 10;
  background-color: white;
  overflow: hidden;
  
  @media screen and (max-width: 599px) {
    max-width: 100%;
  }
  
  > div {
    display: block;
    position: relative;
    padding: 0 0 0 20px+$gutter;
    //border: 1px solid $border_col;
    //border-bottom: none;
    //border-top: 1px solid $border_col;
    //background-color: $panel_bg;
    @extend %reset;
    
    &:not(:last-of-type) {
      margin: 0 0 10px;
    }
          
    @if lightness($panel_bg) < 50% {
      color: invert($label_color);
    } @else {
      color: $label_color;
    } 
    
    @for $i from 1 through $items {
      &:nth-child(#{$i}) {        
        &:before {
          content: '#{$i}';
          width: 20px;
          font-weight: bold;
          text-align: center;
          position: absolute;
          left: 0;
          top: 0;
          //background-color: $num_bg;
          //color: darken(white, 15%);
          padding: $gutter $gutter/2;
          border-right: 1px solid rgba($border_col, 0.25);
          margin: 0;
          
          @if lightness($num_bg) < 50% {
            color: invert($label_color);
          } @else {
            color: $label_color;
          }  
        }
      }
    }
    input {
      & + label {
        cursor: pointer;
        display: block;
        padding: $gutter $gutter;
        //background-color: $label_bg;
        transition: all 0.25s ease-in-out 0.5s, color 0.25s ease-in-out 0.5s;    

        @if lightness($label_bg) < 50% {
          color: invert($label_color);
        } @else {
          color: $label_color;
        }         
      }
      & ~ div {
        visibility: hidden;
        max-height: 0;
        opacity: 0;
        transition: all 0.5s ease-in-out 0.2s, opacity 0.25s ease-in-out 0.25s, padding 0s ease-in-out 0s;
        width: calc(100% + 35px);
        margin-left: -35px;
        
        p {
          padding: $gutter;
          border-top: 1px solid rgba($border_col, 0.25);
        }
      }
      &:checked {
        & + label {
          //background-color: $label_bg_active;
          transition: background-color 0s ease-in-out 0s;
          
          @if lightness($label_bg_active) < 50% {
            color: invert($label_color);
          } @else {
            color: $label_color;
          } 
        }
        & ~ div {
          display: block;
          opacity: 1;
          visibility: visible;
          max-height: $panelheight;
          transition: all 0.5s ease-in-out 0.2s, opacity 0.25s ease-in-out 0.5s, padding 0s ease-in-out 0s;
        }
      }
    }
  }
}

.details {
  width: calc(100% - #{$gutter*2});
  float: right;
  margin: $gutter;
  padding: $gutter;
  background-color: lighten(#0066cc,20%);

  @media screen and (max-width: 599px) {
    float: left;
    width: calc(100% - #{$gutter*2});
    margin: $gutter;
  }
  
  * {
    display: inline-block;
    margin: $gutter/2 0;
    line-height: 20px;
    color: white;
  }
  h1 {
    font-size: 36px;
    font-weight: bold;
    line-height: 40px;
    padding: 0;
    margin: 0;
    clear: both;
  }
  a {
    clear: both;
    font-family: "FontAwesome", Sans-Serif;
    font-size: 14px;
    text-decoration: none;
    
    &.follow {
      float: left;
      font-weight: bold;
      background-color: lighten(black,20%);
      margin: $gutter 0 0;
      padding: $gutter/2 $gutter;
      cursor: pointer;
      
      &:before {
        margin: 0 $gutter/2 0 0;
      }
      
      strong {
        color: lighten(#0066cc,40%);
        @extend %reset;
      }
      
      &:hover {
        background-color: lighten(#0066cc,30%);
        
        strong {
          color: lighten(black,30%);
        }
      }
    }
  }
}