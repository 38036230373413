.flew {
  flex-direction: row;
  justify-content: space-between;
  padding: 0 121px;
  flex-wrap: wrap;
}
.savings_card {
  margin-bottom: 26px;
  max-width: calc(50% - 16px);
  min-height: 103px;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 14px;
  align-items: center;
  display: flex;
  font-size: 100%;
}
.savings_image {
  height: 100%;
  min-width: 125px;
}
.savings-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
  max-width: 125px;
}
.big {
  height: 103%;
  width: 100%;
}
.savings_text_wrap {
  padding: 25px;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  display: flex;
}

.savings_header_wrap {
  margin-bottom: 0.5rem;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
}

.savings_text {
  width: 100% !important;
  font-size: 1.25rem;
  font-weight: 500;
  color: black;
}
.savings_summary {
  font-size: 0.875rem;
  line-height: 1.45;
  color: black;
}
.tab_text {
  color: white;
  font-size: 1.9rem;
  font-weight: 500;
  width: 100%;
  cursor: pointer;
  text-align: center;
}
.tab_text2 {
  // color: white;
  color: #99bf18;
  background: white;
  border-radius: 20px;
  font-size: 1.9rem;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  text-align: center;
}
.tab_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid white;
  margin-bottom: 50px;
}
.web_lap {
  height: 80%;
  width: 80%;
}
.mob {
  height: 22%;
  width: 22%;
}
.mob2 {
  height: 32%;
  width: 32%;
  position: relative;
  top: -10px;
}
.mob3 {
  height: 25%;
  width: 25%;
  position: relative;
  top: -10px;
}

@media only screen and (max-height: 798px) and (max-width: 1440px) and (min-width: 768px) {
  .savings-image img {
    max-width: 116px;
  }
}

@media screen and (max-width: 900px) {
  .tab_text2 {
    font-size: 1rem;
    padding: 5px;
    margin-right: 5px;
  }
  .tab_text {
    font-size: 1rem;
  }
  .flew {
    flex-direction: column;
    padding: 0;
  }
  .savings_card {
    max-width: 100%;
  }
}
