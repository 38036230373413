@media only screen and (min-width: 768px) {
  .mobile-hamburger {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  body,
  html {
    font-size: 15px;
  }
  .container {
    padding-left: 5%;
    padding-right: 5%;
  }
  .buddy-savings--shapeItems {
    position: absolute;
    right: 5% !important;
  }
  .fold-illustration img {
    height: 800px;
    right: -650px;
    bottom: -125px;
  }
  .buddy-entry--fold {
    min-height: 680px;
  }
}

@media only screen and (min-width: 1350px) and (max-width: 1440px) {
  .fold-illustration img {
    right: -317px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .buddy-savings--shapeItems {
    position: absolute;
    right: 25px !important;
  }
  body,
  html {
    font-size: 14px;
  }
  .buddy-screens--wrap img {
    max-width: 208px;
  }
  .web-screen--wrap img {
    max-width: 650px;
  }
  .entry-text--wrap {
    max-width: 400px;
  }
  .buddy-standard--wrap {
    max-width: 380px;
  }
  .buddy-center--section:not(.buddy-saving--section) .buddy-standard--wrap {
    max-width: 580px;
  }
  .animate-coin svg {
    height: 50px;
    width: auto;
  }
  .web-screen--wrap img {
    max-width: 650px;
  }
  .animate-coin svg {
    height: 50px;
    width: auto;
  }
  .buddy-saving--illustration .mock {
    width: 200px;
  }
  .buddy-leaf--left svg {
    right: 222px;
    width: 180px;
  }
  .buddy-leaf--right svg {
    width: 180px;
  }
  .growth-mock--section .buddy-standard--wrap {
    top: -20px;
  }
  .growth-mock--section {
    padding-top: 40px;
  }
  .buddy-saving--illustration .mock {
    top: -40px;
  }
  .buddy-menu li:not(:last-child) {
    margin-right: 33px;
  }
  .buddy-nav--actions {
    width: 100%;
    max-width: 260px;
  }
  .buddy-nav--brand {
    margin-right: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fold-illustration img {
    height: 706px;
    right: -650px;
    bottom: -280px;
  }
  .buddy-entry--fold {
    min-height: 600px;
  }
  .buddy-screens--wrap img:not(:last-child) {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fold-illustration {
    display: none;
  }
  .buddy-entry--fold .buddy-section--50 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .buddy-entry--fold .buddy-cta--lg {
    margin-left: auto;
    margin-right: auto;
  }
  .buddy-entry--fold {
    min-height: 500px;
  }
  .leaf-left--below---fold {
    top: -25%;
  }
  .web-screen--wrap img {
    max-width: 600px;
  }
  .buddy-screens--wrap img:not(:last-child) {
    margin-right: 20px;
  }
  .buddy-screens--wrap img {
    max-width: 185px;
  }
  .buddy-nav--brand svg {
    height: 27px;
    width: auto;
  }
  .buddy-nav--brand {
    margin-right: 25px;
  }
  .buddy-entry--fold .buddy-section--50 {
    width: calc(100% - 20px);
  }
  .entry-text--wrap {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 767px) {
  body,
  html {
    font-size: 13.5px;
  }
  .buddy-menu,
  .buddy-nav--actions {
    display: none;
  }
  .buddy-nav--brand svg {
    height: 28px;
    width: auto;
  }
  .buddy-section--50 {
    width: 100%;
  }
  .buddy-entry--fold {
    min-height: 100%;
    padding-bottom: 35px;
    padding-top: 150px;
  }
  .below-fold--illustrations {
    display: none;
  }
  .buddy-section {
    flex-direction: column;
    margin: 25px 0;
  }
  .buddy-section--50 {
    margin-bottom: 30px;
  }
  .fold-illustration {
    display: none;
  }
  header {
    height: 75px;
  }
  .text-heading {
    font-size: 2.3rem;
  }
  .buddy-standard--wrap .text-heading {
    font-size: 1.8rem;
  }
  .step-icon img {
    width: 45px;
    height: 45px;
    object-fit: cover;
  }
  .buddy-step--text {
    padding: 0 0px 0 20px;
  }
  .buddy-screens--wrap img {
    width: 100%;
  }
  .web-solution--illustration {
    display: none;
  }
  .web-coin--illustration {
    display: none;
  }
  .mobile-solutions--illustration {
    display: none;
  }
  .buddy-screens--wrap:not(.web-screen--wrap) img:first-child,
  .buddy-screens--wrap:not(.web-screen--wrap) img:last-child {
    display: none;
  }
  .buddy-screens--wrap:not(.web-screen--wrap) img {
    max-width: 230px;
  }
  .buddy-screens--wrap img {
    margin: 0 0 25px 0 !important;
  }
  .cta-item {
    width: 100%;
    margin: 0 !important;
  }
  .cta-item:not(:last-child) {
    margin-bottom: 30px !important;
  }
  .cta-item--heading {
    font-weight: 500;
    margin-bottom: 7px;
    text-align: center;
  }
  .cta-item--body,
  .buddy-standard--wrap .text-subheading,
  .buddy-standard--wrap .text-heading {
    text-align: center;
  }
  .entry-text--wrap {
    text-align: center;
  }
  .buddy-cta--lg {
    margin-left: auto;
    margin-right: auto;
  }
  .buddy-saving--illustration {
    margin-top: 150px;
  }
  .buddy-saving--illustration .mock {
    top: 217px;
    max-width: 230px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  .growth-mock--section {
    padding-top: 90px;
  }
  .buddy-saving--illustration {
    margin-top: 200px;
  }
  .buddy-saving--illustration .mock {
    top: 240px;
    max-width: 230px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  .buddy-leaf--left svg {
    right: auto;
    right: calc(50% + 120px);
    transform: translateX(-50%);
    width: 150px;
  }
  .footer-column {
    width: 33.33%;
    margin-bottom: 50px;
    text-align: center;
  }
  .buddy-footer--columns {
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .column-is--two {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
  }
  .download-badges {
    margin-left: auto;
    margin-right: auto;
  }
  .footer-column .buddy-cta--lg {
    margin-left: auto;
  }
  .buddy-copyright--section * {
    text-align: center;
  }
  .buddy-entry--header {
    max-width: 100%;
  }
  .buddy-entry--header {
    max-width: 100%;
  }
  footer .container {
    max-width: 100%;
  }
  .padding-top--small {
    padding-top: 0;
  }
  .buddy-standard--section {
    padding-bottom: 0;
  }
}
